@font-face {
  font-family: 'Bitter';
  src: local("Bitter"), url('../assets/font/Bitter/BitterPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Bookerly';
  src: local("Bookerly"), url('../assets/font/Bookerly/Bookerly-Regular.ttf') format('truetype');
}

* {
  font-family: Bitter !important;
}

*::selection {
    background: #52b788;
}
*::-moz-selection {
  background: #52b788;
}
*::-webkit-selection {
    background: #52b788;
}